@import '~swiper/swiper-bundle.min.css';

.logo-ticker {
    height: 100px;
    .swiper-slide {
        height: 100px;
        width: 100px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}
